import React from 'react';

export const AppDataContext = React.createContext({
  isLoading: true,
  movies: [],
  series: [],
  books: [],
  showcases: [],
  labels: []
});

export const AppDataContextProvider = AppDataContext.Provider;
