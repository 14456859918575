import { useCookieConsent } from 'components/CookieConsent/hooks';
import { ShowEpisodeCard } from 'components/SeriesEpisodeCard/ShowEpisodeCard';
import { useVideoPlayer } from 'hooks/useVideoPlayer';
import { PageWrapper } from 'components/Wrapper/PageWrapper';
import { useAppData } from 'hooks/useAppData';
import { ScrollList } from 'components/ScrollList/ScrollList';
import { seriesUrl } from 'root/urls';
import { ExpandingLink } from 'components/ExpandingLink/ExpandingLink';

export const HomeShowcase = ({ seriesSlug, seasonSlug, title, reverse }) => {

  const { getSeasonByCode, allLabels } = useAppData();
  const { consentOptions } = useCookieConsent();
  const { open } = useVideoPlayer();
  const season = getSeasonByCode(seriesSlug, seasonSlug);

  if (!season || !season?.episodes) return null;
  if (reverse) {
    season.episodes.sort((a, b) => {
      if (a.id < b.id) return 1;
      return -1;
    });
  }

  return (
    <>
      <PageWrapper>
        <ExpandingLink
          title={title}
          linkTo={`${seriesUrl}/${seriesSlug}/${seasonSlug}`}
          linkText={allLabels.labelExpandingLinkAllSeries}
        />
      </PageWrapper>
      <ScrollList
        items={season.episodes}
        renderItem={(episode) => (
          <ShowEpisodeCard
            episode={episode}
            consentOptions={consentOptions ?? {}}
            onPlay={() =>
              open({
                video: episode,
                path: ['series', seriesSlug, seasonSlug, episode.id],
                playlist: season.episodes,
              })
            }
          />
        )}
      />
    </>
  );
};

export default HomeShowcase;
