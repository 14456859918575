import PosterGrid from 'components/PosterGrid/PosterGrid';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMovies } from 'views/Movies/hooks/useMovies';
import { useSeries } from 'hooks/useSeries';
import { isNil } from 'utils/function';
import { sortByTitleAsc, removeDuplicatesById } from 'utils/utils';

const searchItems = (query) => (item) => {
  if (isNil(item.title)) return false;
  return item.title.toLowerCase().search(new RegExp(query, 'gi')) > -1;
}

export const SearchView = () => {
  const [searchParams] = useSearchParams();

  const query = searchParams.get('query').toLowerCase();

  const seasons = useSeries(undefined, searchItems(query), {
    mini: true,
  });

  const series = useSeries(searchItems(query), undefined, {
    mini: true,
  });

  const movies = useMovies(searchItems(query), { mini: true });

  const videos = useMemo(() => {
    const data = [...movies, ...series, ...seasons];
    return removeDuplicatesById(data).sort(function (a, b) { return sortByTitleAsc(a, b) });
  }, [movies, series, seasons]);

  return (
    <PosterGrid
      videos={videos}
      topic={videos.length === 0 ? 'Ei hakutuloksia' : 'Hakutulokset'}
      mini
    />
  );
};

export default SearchView;
