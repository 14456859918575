import React, { useMemo } from 'react';
import { useAppData } from 'hooks/useAppData';
import { HomeHeroBlock } from './HomeHeroBlock';
import { MoviesPosterGrid } from 'views/Movies/MoviesPosterGrid';
import { HomeShowcase } from './HomeShowcase';
import { ViewingHistory } from './ViewingHistory';
import { SeriesPosterGrid } from 'components/SeriesPosterGrid/SeriesPosterGrid';
// import { notForChildren } from 'views/shared/utils';

// function useLargePosterFilter(season) {
//   return season.useLargePoster;
// }

export const Home = () => {
  const { allSeries, allMovies, allShowCases } = useAppData();

  const homeShowCases = useMemo(
    () =>
      allShowCases.map((hsc, index) => (
        <HomeShowcase
          key={`showcases-${index}`}
          allSeries={allSeries}
          seriesSlug={hsc.seriesSlug}
          seasonSlug={hsc.seasonSlug}
          title={hsc.title}
          reverse={hsc.reverse}
        />
      )),
    [allShowCases, allSeries]
  );

  return (
    <>
      <HomeHeroBlock allSeries={allSeries} allMovies={allMovies} />
      <ViewingHistory limit={4} />
      <SeriesPosterGrid
         //seriesFilter={notForChildren}
         seriesFilter={() => true}
         //seasonFilter={useLargePosterFilter}
         seasonFilter={() => true}
         sortBySticker
      />
      <MoviesPosterGrid
         //filter={notForChildren}
         filter={() => true}
         sortBySticker
      />
      {homeShowCases}
    </>
  );
};
