import PropTypes from 'prop-types';
import { VideoPlayerCard } from 'components/VideoPlayerCard/VideoPlayerCard';

export const ShowEpisodeCard = ({ episode, consentOptions, onPlay }) => {
  return (
    <VideoPlayerCard
      {...episode}
      consentOptions={consentOptions}
      onPlay={onPlay}
      isLazy={true}
    />
  );
};

ShowEpisodeCard.propTypes = {
  episode: PropTypes.object,
  consentOptions: PropTypes.object,
  onPlay: PropTypes.func,
};

export default ShowEpisodeCard;
