export const breakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1440,
};

export const media = {
  sm: `(min-width: ${breakpoints.sm}px)`,
  md: `(min-width: ${breakpoints.md}px)`,
  lg: `(min-width: ${breakpoints.lg}px)`,
  xl: `(min-width: ${breakpoints.xl}px)`,
  max: {
    sm: `(max-width: ${breakpoints.md - 1}px)`,
    md: `(max-width: ${breakpoints.lg - 1}px)`,
    lg: `(max-width: ${breakpoints.xl - 1}px)`,
  },
};
