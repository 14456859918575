import React from 'react';
import PropTypes from 'prop-types';
import PosterGrid from 'components/PosterGrid/PosterGrid';
import { seriesUrl } from 'root/urls';
import { useSeries } from '../../hooks/useSeries';
import { ScrollList } from 'components/ScrollList/ScrollList';
import { PosterCard } from 'components/PosterCard/PosterCard';
import { sortByTitleAsc, sortByStickerFn } from '../../utils/utils';


export function SeriesPosterGrid({
  scroll,
  mini,
  seriesFilter,
  seasonFilter,
  topic = 'Sarjoja',
  topicUrl = seriesUrl,
  filters,
  sortBySticker = false,
}) {
  let videos = useSeries(seriesFilter, seasonFilter, {
    mini,
    sort: sortBySticker ? sortByStickerFn : sortByTitleAsc,
  });

  if (scroll) {
    return (
      <ScrollList
        items={videos}
        renderItem={(video) => <PosterCard {...video} aspectRatio={56.25} />}
      />
    );
  }

  return (
    <PosterGrid
      topic={topic}
      topicUrl={topicUrl}
      videos={videos}
      cardSizeMultiplier={!mini ? 0.75 : undefined}
      filters={filters}
      aspectRatio={150}
      mini={mini}
      isLazy={mini}
    />
  );
}

SeriesPosterGrid.propTypes = {
  scroll: PropTypes.bool,
  topic: PropTypes.string,
  topicUrl: PropTypes.string,
  topicLinkLabel: PropTypes.string,
  mini: PropTypes.bool,
  seriesFilter: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func),
  ]),
  seasonFilter: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func),
  ]),
  filters: PropTypes.node,
};
