import { useEffect, useState } from 'react';

const timeNow = Date.now().toString();

async function getSingleSeries(series) {
  let slug;
  try {
    slug = "/api/" + series + ".json";
    const response = await fetch(slug);
    return await response.json();
  } catch (error) {
    throw new Error(`Reason: ${slug} is missing.`);
  }
}

async function getSeries() {
  try {
    const response = await fetch(`/api/series.json?t=${timeNow}`);
    const seriesList = await response.json();

    return await Promise.all(
      seriesList.list.map(async (series) => {
        return await getSingleSeries(series);
      })
    );
  } catch (error) {
    throw new Error(error.message);
  }
}

async function getJSONFile(file) {
  try {
    const json = '/api/' + file + '.json?t=' + timeNow;
    const response = await fetch(json);
    return await response.json();
  } catch (error) {
    throw new Error('Error loading ' + file + '.');
  }
}

export const useFetchAppData = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();
  const [movies, setMovies] = useState([]);
  const [series, setSeries] = useState([]);
  const [books, setBooks] = useState([]);
  const [showcases, setHomeShowCases] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    async function getData() {
      try {
        const config = await getJSONFile('config');
        const books = await getJSONFile('books');
        const moviesjson = await getJSONFile('movies');
        const seriesjson = await getSeries();

        setHomeShowCases(config.homeshowcases);
        setLabels(config.labels[0]);
        setBooks(books);
        setMovies(moviesjson);
        setSeries(seriesjson);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    }
    getData();
  }, []);

  return {
    isLoading,
    movies,
    series,
    books,
    showcases,
    labels,
    error,
  };
};